export { IgdbCategory } from './category'
export { IgdbStatus } from './status'
export { IgdbWebsiteCategory } from './websiteCategory'

export type { IgdbCompany } from './company'
export type { IgdbEngine } from './engine'
export type { IgdbEvent } from './event'
export type { IgdbFranchise } from './franchise'
export type { IgdbGame } from './game'
export type { IgdbGenre } from './genre'
export type { IgdbImage } from './image'
export type { IgdbMode } from './mode'
export type { IgdbMultiplayerMode } from './multiplayerMode'
export type { IgdbPlatform } from './platform'
export type { IgdbPlayerPerspective } from './playerPerspective'
export type { IgdbReleaseDate } from './releaseDate'
export type { IgdbTheme } from './theme'
export type { IgdbVideo } from './video'
export type { IgdbWebsite } from './website'
