export enum IgdbCategory {
	MainGame = 0,
	DlcAddon = 1,
	Expansion = 2,
	Bundle = 3,
	StandaloneExpansion = 4,
	Mod = 5,
	Episode = 6,
	Season = 7,
	Remake = 8,
	Remaster = 9,
	ExpandedGame = 10,
	Port = 11,
	Fork = 12,
}
