export enum IgdbWebsiteCategory {
	Official = 1,
	Wiki = 2,
	Wikipedia = 3,
	Facebook = 4,
	Twitter = 5,
	Twitch = 6,
	Instagram = 8,
	YouTube = 9,
	Iphone = 10,
	Ipad = 11,
	Android = 12,
	Steam = 13,
	Reddit = 14,
	Itch = 15,
	EpicGames = 16,
	GoG = 17,
	Discord = 18,
}
