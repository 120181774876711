export enum IgdbStatus {
	Released = 0,
	Alpha = 1,
	Beta = 2,
	EarlyAccess = 3,
	Offline = 4,
	Cancelled = 5,
	Rumored = 6,
	Delisted = 7,
}
