export enum GameCategory {
	MainGame = 'MainGame',
	DLCAddon = 'DLCAddon',
	Expansion = 'Expansion',
	Bundle = 'Bundle',
	StandaloneExpansion = 'StandaloneExpansion',
	Mod = 'Mod',
	Episode = 'Episode',
	Season = 'Season',
	Remake = 'Remake',
	Remaster = 'Remaster',
	ExpandedGame = 'ExpandedGame',
	Port = 'Port',
	Fork = 'Fork',
}
